.card-text {
  text-align: left;
  margin: 12px 32px;
}

a,
u {
  text-decoration: none;
  color: #c84b55;
}

.spacer {
  height: 300px;
}
