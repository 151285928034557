.wrapper {
  padding: 5px 20px;
}

.wrapper fieldset {
  margin: 20px 0;
}

#inputBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
#inputForm {
  width: 65%;
  height: 48px;
  left: 382px;
  top: 418px;
  border: 1px solid var(--off-black);
  box-sizing: border-box;
  border-radius: 6px;
}

#notify {
  background: radial-gradient(
      120.58% 93.5% at 131% -11%,
      #fb7373 0%,
      rgba(251, 116, 116, 0.75) 25%,
      rgba(251, 120, 116, 0.25) 75%,
      rgba(251, 115, 135, 0) 100%
    ),
    radial-gradient(
      86% 86% at 4.5% 0%,
      #f56084 0%,
      rgba(244, 97, 133, 0.5) 25%,
      rgba(244, 97, 133, 0) 100%
    ),
    #d5639d;
  color: white;
  width: 25%;
  height: 48px;
  left: 838px;
  top: 418px;
  margin: 8px;
  box-sizing: border-box;
  border-radius: 6px;
  border: none;
}

.bold {
  justify-content: flex-start;
}
.column {
  justify-content: start;
}
@media screen and (max-width: 375px) {
  #inputForm {
    width: 90%;
  }
  #notify {
    width: 90%;
  }
  #inputBox {
    display: flex;
    flex-direction: column;
    /* justify-content: space-around;
    align-items: center; */
  }
}
