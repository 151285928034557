.footer {
  color: white;
  margin-top: 1rem;
  padding: 1rem auto;
  background-color: var(--off-black);
  position: relative;
  /* bottom: 0; */
  width: 100%;
  height: 226px;
  border-radius: 0px;
}
/* 
  .fake-footer {
    display: block;
    padding: 20;
    height: 226;
    width: 100%;
  } */

.footer-container {
  max-width: 740px;
  margin: auto;
  padding-left: 3.75%;
  padding-right: 3.75%;
}

.footer-col {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 3.75%;
  padding-right: 3.75%;
}

.brand-img {
  margin: auto;
  width: 84px;
  padding-bottom: 12px;
  /* height: 24px; */
}

.social-img {
  width: 24px;
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
}

.copyright {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 12px;
  left: 0;
  right: 0;
  text-align: center;
}
