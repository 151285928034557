.App {
  text-align: center;
  background-color: var(--off-white);
  /* position: absolute;
  top: 0; */
  margin: auto;
  height: 100%;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Flex box */

.flex-me {
  display: flex;
  justify-content: space-between;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

/* Headers */

.header-brand {
  color: var(--off-black);
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  padding: 36px 32px 48px;
}

.left-bokeh {
  position: absolute;
  top: -33%;
  left: -33%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f6587c;
  filter: blur(250px);
  z-index: 0;
}

.right-bokeh {
  position: absolute;
  top: -50%;
  right: -50%;
  width: 110%;
  height: 110%;
  border-radius: 50%;
  background-color: #ff7481;
  filter: blur(250px);
  z-index: 0;
}

.header-brand-flair {
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  padding: 36px 3.75% 48px;
}

.header-flair-option {
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-align: right;
  padding: 36px 3.75% 48px;
}

.header-text-flair {
  color: white;
  font-size: 36px;
  font-weight: 700;
  padding: 24px 32px 16px;
}

.subheader-flair {
  color: white;

  font-size: 20px;
  font-weight: 500;
  padding: 0px 32px 32px;
}

.header-footer {
  color: #999;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
}

.header-brand-footer {
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  padding-bottom: 8px;
}

.header-big {
  color: var(--off-black);
  font-size: 48px;
  font-weight: 900;
  text-align: left;

  max-width: 740px;
  margin: auto;
  padding-bottom: 24px;
  padding-left: 3.75%;
  padding-right: 3.75%;
}

.header-small {
  color: var(--off-black);
  font-size: 16px;
  font-weight: 700;
  text-align: left;

  max-width: 740px;
  margin: auto;
  padding: 60px;
  padding-bottom: 24px;
  padding-left: 3.75%;
  padding-right: 3.75%;
}
.header-jump {
  color: var(--off-black);
  font-size: 16px;
  font-weight: 700;
  text-align: left;

  max-width: 740px;
  margin: auto;
  padding-bottom: 160px;
  padding-left: 3.75%;
  padding-right: 3.75%;
}

.header-date {
  color: #999999;
  font-size: 16px;
  font-weight: 500px;
  height: 19.36px;
  text-align: left;

  max-width: 740px;
  margin: auto;
  padding-bottom: 32px;
  padding-left: 3.75%;
  padding-right: 3.75%;
}
/* Style Text */

.bold {
  font-weight: bold;
}

/* Body Text */

.body-text {
  color: var(--off-black);
  font-size: 18px;
  text-align: left;

  max-width: 740px;
  margin: auto;
  padding-left: 3.75%;
  padding-right: 3.75%;
}

.body-text-legal {
  color: var(--off-black);
  font-size: 16px;
  text-align: left;
  line-height: 32px;

  max-width: 740px;
  margin: auto;
  padding-left: 3.75%;
  padding-right: 3.75%;
}

.language-control {
  padding: 36px 72px;
  height: 24;
  width: 56;
}

.card-landing {
  max-width: 740px;
  min-width: 100px;
  /* height: 320px; */
  margin: auto;
  padding-top: 32px;
  padding-bottom: 32px;

  background-color: white;
  border-radius: 6px;
  box-shadow: 32px 32px 80px 0px #00000010;
}

.bullet-row {
  margin: 8px 32px;
  max-width: 300px;
  text-align: left;
  justify-content: flex-start;
}

.bullet-icon {
  width: 20px;
  margin-right: 16px;
}

/* Flair Marketing */

.flair-container {
  background-color: #725ae5;
  position: absolute;
  top: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.flair-marketing {
  max-width: 740px;
  margin: auto;
  padding-left: 3.75%;
  padding-right: 3.75%;
}

/* .flair-header-section {
  min-height: 100px;
} */

.flair-white {
  width: 120px;
}

.flair-input {
  width: 100%;
  height: 42px;
  font-size: 20px;
  color: white;
  z-index: 1;
  background-color: transparent;
  padding: 1px 8px;
  border: 2px solid whitefff;
  border-radius: 6px;
  box-shadow: 32px 32px 80px #00000010;
  margin-right: 24px;
}

.flair-input::placeholder {
  color: #dbdbdb;
}

.flair-button {
  min-width: 220px;
  height: 48px;
  font-size: 20px;
  color: #725ae5;
  z-index: 1;
  background-color: white;
  border-radius: 6px;
  box-shadow: 32px 32px 80px #00000010;
  border-color: transparent;
}

.zz-top {
  position: relative;
  z-index: 0;
}

.flair-feature {
  max-width: 740px;
  margin: auto;
}

.flair-feature-header {
  max-width: 350px;
  font-size: 24px;
  font-weight: 700;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
}

.flair-feature-body {
  max-width: 350px;
  font-size: 16px;
  color: white;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.white {
  color: white;
}

.temp-image {
  width: 360px;
  height: 360px;
  background-color: blue;
}
